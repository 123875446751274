<template>
  <v-app>
    <v-container>
      <v-row class="pl-2">
        <v-col class="pa-5" cols="12" sm="12" md="12">
          <v-radio-group v-model="question.question_type" row>
            <v-radio label="Điền đáp án" :value="1"></v-radio>
            <v-radio label="Viết đoạn văn" :value="2"></v-radio>
          </v-radio-group>
        </v-col>
      </v-row>
      <v-row class="align-center justify-center">
        <v-col cols="11">
          <div class="subtitle-1 font-weight-bold">1.Câu hỏi</div>
          <CkContent
              validateName="question"
              :content="question.content"
              @getData="getQuestion"
              class="my-5 editor-content"
          ></CkContent>
        </v-col>

      </v-row>
      <v-row class="align-center justify-center">
        <v-col cols="11">
          <div class="subtitle-1 font-weight-bold">2.Dịch câu hỏi</div>
          <CkContent
              validateName="question"
              :content="questionDetail.content_translate"
              @getData="getTranslateQuestion"
              class="my-5 editor-content"
          ></CkContent>
        </v-col>
      </v-row>
      <v-row class="align-center justify-center">
        <v-col cols="11">
          <div class="subtitle-1 font-weight-bold">3.Câu trả lời</div>
          <CkContent
              validateName="question"
              :content="answer.content"
              @getData="getAnswer"
              class="my-5 editor-content"
          ></CkContent>
        </v-col>
      </v-row>
      <v-row class="justify-center text-center">
        <v-col>
          <v-btn color="indigo" class="white--text" @click="submitQuestion" width="200px"
          >Lưu câu hỏi
          </v-btn>
        </v-col
        >
      </v-row>
    </v-container>
  </v-app>
</template>
<script>
import {
  SAVE_EXERCISE_CONTENT,
  GET_EXERCISE_DETAIL,
  GET_QUESTION_DETAIL,
  UPDATE_EXERCISE_CONTENT
} from "@/store/exercises.module";
import CkContent from "@/components/ck-content";
import {mapGetters} from "vuex";

export default {
  data: () => ({
    answer: {content: ''},
    question: {},
    dialog: false,
    type: 1,
  }),
  components: {
    CkContent,
  },
  computed: {
    ...mapGetters({
      exercise: "exerciseDetail",
      questionDetail: "questionDetail",
    })
  },
  watch: {
    questionDetail(newValue) {
      this.answer.content = newValue.answers[0].content
      this.answer.id = newValue.answers[0].id
      this.question.content = newValue.content
      this.question.question_type = newValue.question_type
    }
  },
  async created() {
    await this.$store.dispatch(GET_EXERCISE_DETAIL, {
      exercise_slug: this.$route.query.exercise_slug,
    });
    await this.$store.dispatch(GET_QUESTION_DETAIL, {
      question_id: this.$route.query.question_id,
    });
    if (this.$route.query.question_id) {
      this.isUpdate = true;
      this.question.id = this.$route.query.question_id
    }
  },

  methods: {
    submitQuestion() {
      if (!this.question.question_type) {
        this.$toasted.error('Bạn chưa chọn loại câu hỏi', {
          position: "top-right",
          duration: 3000
        })
        return false
      }
      if (!this.question.content) {
        this.$toasted.error('Bạn chưa nhập câu hỏi', {
          position: "top-right",
          duration: 3000
        })
        return false
      }
      if (!this.answer.content) {
        this.$toasted.error('Bạn chưa nhập câu trả lời', {
          position: "top-right",
          duration: 3000
        })
        return false
      }
      if (this.question.content && this.answer.content) {
        // get type of question
        if (this.isUpdate) {
          this.$store
              .dispatch(UPDATE_EXERCISE_CONTENT, {
                question: this.question,
                answers: [this.answer],
                exercise: this.exercise,
              })
              .then((data) => {
                if (data.data.status) {
                  this.$router.go(-1);
                }
              });
        }
        if (!this.isUpdate) {
          this.$store
              .dispatch(SAVE_EXERCISE_CONTENT, {
                question: this.question,
                answers: [this.answer],
                exercise: this.exercise,
              })
              .then((data) => {
                if (data.data.status) {
                  this.$router.go(-1);
                }
              });
        }
      }

    },
    getAnswer(value) {
      this.answer.content = value
    },
    getQuestion(value) {
      this.question.content = value
    },
    getTranslateQuestion(value) {
      this.questionDetail.content_translate = value
    }
  }
};
</script>
